<template>
  <div promotion-header>
    <img :src="logo" />
    <Slider :info="tabList" slides-per-view="auto">
      <template v-slot:slide="{info}">
        <a :class="{active: nowPage === info.label}" :href="tabLink(info.path)">{{ info.label }}</a>
      </template>
    </Slider>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { baseList } from '@/constants/base/siteMap.mjs';
import Slider from '@shared/components/general/Slider.vue';

export default {
  name: 'PromotionHeader',
  components: { Slider },
  data() {
    return {
      logoPath: null,
      promotionInfo: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    accessibleList() {
      return Object.keys(this.promotionInfo).filter(o => this.promotionInfo[o]);
    },
    logo() {
      return baseList.find(e => e.site === this.site)?.logo;
    },
    nowPage() {
      return this.$route?.name;
    },
    tabList() {
      const list = [];
      const promotionRouter = this.$router?.options?.routes?.[0]?.children?.filter(o => o.name === "Promotion")?.[0];
      if (promotionRouter) promotionRouter?.children?.forEach(o => list.push({ label: o.name, path: o.path}));
      return list;
    }
  },
  methods: {
    tabLink(path) {
      if (!path) path = 'free';
      if (process.env.VUE_APP_ENV === 'local') return `/promotion/${path}`;
      return `/${path}`;
    },
  }
};
</script>

<style lang="less">
@import "@/less/tpm";
[promotion-header] { .sticky(); .t(0); .wf(); .bgc(#222); .z(100);
  > img { .h(40); .w(auto); .block(); .mh-c(); .mv(20);
    &:not(:last-child) { .mb(0); }
  }
  [slider] { .flex(); .gap(12); .justify-center(); .mt(20); .max-w(1030); .ph(20); .mh-c();
    .swiper-slide { .w(); .min-w(auto); .p(0);
      + .swiper-slide { .-l(); }
      a { .block(); .p(8, 20); .-b(transparent, 2px); text-transform: capitalize;
        &.active { .-b(@c-red, 2px);}
      }
    }
  }
}
</style>
